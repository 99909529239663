.breadcrumbs {
  margin-bottom: 70px;
  padding-top: 30px;

  ul {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;

    li {
      @include font(12, 16);
      color: rgba(62, 62, 62, 0.5);
      text-transform: lowercase;
      letter-spacing: 0.36px;

      a {
        @include not-hover;
        text-decoration: none;

        @media (min-width: 1025px) {
            &:hover {
              @include hover;
              opacity: 0.7;
            }
          }
      }

      &:not(:last-child)::after {
        content: '>';
        margin-left: 2px;
        margin-right: 4px;
        color: inherit;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .breadcrumbs {
    margin-bottom: 30px;
  }
}