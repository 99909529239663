.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form__fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;

  .form__field {
    width: 49%;
    margin-right: 2%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.form__field {
  position: relative;
  
  input,
  textarea {
    width: 100%;
    font-size: 20px;
    line-height: 20px;
    padding: 19px 30px 15px;

    color:rgba(62, 62, 62, 0.5);

    border-radius: 20px;
    border: 1px solid rgba(62, 62, 62, 0.3);

    &:not(:hover) {
      transition: 0.3s;
    }

    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      transition: 0.3s;
      border-color: rgba(62, 62, 62, 0.7);
    }
      
    &:focus {
      
      & + .form__field-placeholder {
        font-size: 12px;
        line-height: 16px;
        top: 5px;
      }
    }

    &.filled {
      @include hover;

      & + .form__field-placeholder {
        font-size: 12px;
        line-height: 16px;
        top: 5px;
      }
    }
  }

  textarea {
    padding: 25px 30px 15px;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    border-radius: 0;
    margin: 0;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  input[type=number]::-moz-inner-spin-button,
  input[type=number]::-moz-outer-spin-button {
    margin: 0;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  input[type=number]::-ms-inner-spin-button,
  input[type=number]::-ms-outer-spin-button {
    margin: 0;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  input[type=number] {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    min-height: 162px;
    width: 100%;
  }

  &.error-field {
    input {
      border-color: #e6655e;
    }
  }
}

.error-field__text {
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #e6655e;
}

.form__field-placeholder {
  font-size: 20px;
  line-height: 23px;
  position: absolute;
  left: 30px;
  top: 18px;
  color:rgba(62, 62, 62, 0.5);
  transition: 0.3s;
  pointer-events: none;
}

.form__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;

  .button {
    margin-right: 30px;
  }

  p {
    max-width: 260px;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    opacity: 0.5;

    a {
      @include not-hover;
      text-decoration: underline;
      text-decoration-skip-ink: none;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          text-decoration-color: transparent;
        }
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .form__fields-wrapper {
    flex-direction: column;
  
    .form__field {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
  
      &:nth-child(2n) {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {

  .form__fields-wrapper {
    margin-bottom: 20px;
  }

  .form__field {

    input,
    textarea {
      font-size: 14px;
      line-height: 14px;
      padding: 14px 20px 10px;

      &:focus {
      
        & + .form__field-placeholder {
          font-size: 12px;
          line-height: 16px;
          top: 2px;
        }
      }

      &.filled {
  
        & + .form__field-placeholder {
          font-size: 12px;
          line-height: 16px;
          top: 2px;
        }
      }
    }

    textarea {
      min-height: 90px;
      padding-top: 16px;
    }
  }

  .form__field-placeholder {
    font-size: 14px;
    line-height: 14px;
    left: 20px;
    top: 13px;
  }

  .form__buttons {
    flex-direction: column;
    margin-top: 30px;

    .button {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}
