@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin button-reset {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
}

@mixin font($font-size, $line-height) {
  font-size: $font-size * 1px;
  line-height: $line-height * 1px;
}

@mixin pseudo-elem {
  content: "";
  position: absolute;
}

@mixin hover {
  outline: none;
  cursor: pointer;
  transition: $style-change-duration;
}

@mixin not-hover {
  &:not(:hover) {
    transition: $style-change-duration;
  }
}
