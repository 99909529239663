.stage__block {
  position: relative;

  ul {
      list-style-type: none;
    &::before {
      border-radius: .25rem;
      background-color: #a7a9a9;
      bottom: 0;
      content: "";
      left: 20px;
      margin: 0;
      position: absolute;
      top: 0;
      width: 6px;
    }
  }

  li {
    &::before {
      background-image: url("../img/sprite/icon-arrow.svg");
      content: '';
      display: inline-block;
      height: 26px;
      width: 26px;
      position: absolute;
      left: -20px;
      top: 9px;
    }
    position: relative;
    padding: 15px;
    background-color: #97b7ca;
    border-radius: 20px;
    margin: 5px;

    &::marker {
      color: #97b7ca;
      font-size: 1.6em;
    }
  }
}
