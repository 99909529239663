.registration {
    margin-bottom: 80px;
  .certification__list {
    display: flex;
  }  
}


@media (max-width: $tablet-width) {
  .registration {
    margin-bottom: 0;
  }
}