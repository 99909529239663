.button {
  @include button-reset;
  @include not-hover;
  @include font(16, 19);
  padding: 15px 30px;
  font-weight: 400;
  text-align: center;
  border-radius: 50px;
  text-decoration: none;

  @media (min-width: 1025px) {
    &:hover {
      @include hover;
    }
  }

  &--white {
    background-color: #f2f2f2;
    color: #6388a8;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
    
    @media (min-width: 1025px) {
      &:hover {
        color: #f2f2f2;
        background-color: #6388a8;
      }
    }
  }

  &--grey {
    font-weight: 500;
    background-color: #f4f4f4;
    color: #3e3e3e;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 rgba(255, 255, 255, 0.30);

    @media (min-width: 1025px) {
      &:hover {
        background-color: #3e3e3e;
        color: #f4f4f4;
      }
    }
  }

  &--blue {
    padding: 15px 32px;
    font-weight: 500;
    color: #ffffff;
    background-color: #97b7ca;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 rgba(255, 255, 255, 0.30);

    @media (min-width: 1025px) {
      &:hover {
        color: #97b7ca;
        background-color: #ffffff;
      }
    }
  }

  &--dark-blue {
    background-color: #6388a8;
    color: #ffffff;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
    
    @media (min-width: 1025px) {
      &:hover {
        color: #6388a8;
        background-color: #ffffff;
      }
    }
  }
}
