.block-scheme {
  padding: 10px;
  margin: 5px auto;
  outline: 0;
  transition: .3s;
  background-color: #6388a8;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, .15), -5px -4px 35px 0 #fff;
  color: #fff;
  border-radius: 10px;
  width: 50%;
  display: none;
  position: relative;

  @media (max-width: 420px) {
    width: 100%;
  }


  .label{
    position: absolute;
    right: -2px;
    bottom: 0;
    padding: 5px;
    background-color: #e6655e;
    border-radius: 35%;
  }

  .green{
    background-color: #162b2b;
    border-radius: 15%;
  }
}

.block-scheme.active {
  display: flex;
}

.block-scheme_question.active{
    display: flex;
}

.comment{
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &>*{
    height: 40px;
  }
}
.comment .work{
  padding: 10px;
  background-color: #e6655e;
  border-radius: 35%;
  color: white;
}
.comment .calendar{
  padding: 10px;
  background-color: #162b2b;
  border-radius: 15%;
  color: white;
}

.block-scheme_question {
  display: none;
}

.block-scheme_question {
  #display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3px;
}

.block-scheme__item {
  padding: 5px;
  border: 1px #6388a8 solid;
  border-radius: 10px;
  cursor: pointer;
}

.block-scheme__item:first-child {
  margin-right: 5px;
}

.activeQuestion {
  background-color: #e6655e;
}

@media (max-width: 420px) {
  h1 {
    font-size: 18px;
  }
}
