.vacancies {
  margin-bottom: 100px;
  .section-title {
    margin-bottom: 70px;
  }
}

.vacancies__list {
  @include list-reset;
  display: flex;
  flex-direction: column;
}

.vacancies__item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 100px;

  &:last-child {
    margin-bottom: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48.9%;
    margin-right: 2.2%;
    padding: 35px;
    @include font(14, 18);
    border-radius: 20px;
    background-color: #ffffff;

    &:nth-child(2) {
      margin-right: 0;
      background-color: #f4f4f4;
      box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
    }

    h3 {
      margin: 0;
      margin-bottom: 10px;
      @include font(18, 23);
      font-weight: 400;
    }
  
    a {
      @include font(14, 18);
      text-decoration: none;
      color: #6388a8;
      @include not-hover;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }

    ul {
      margin-bottom: 20px;
      list-style-type: disc;
      padding-inline-start: 25px;
    }

    p {
      margin: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .vacancies__item {
    flex-direction: column;

    div {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .vacancies {
    margin-bottom: 60px;
    .section-title {
      margin-bottom: 30px;
    }
  }
  
  .vacancies__item {
    div {
      padding: 20px;
    }
  }
}