.advantages {
  padding-top: 100px;

  .section-title {
    margin-bottom: 70px;
  }
}

.advantages__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
}

.advantages__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 48.2%;
  margin-right: 3.6%;
  margin-bottom: 16px;
  padding: 15px 15px 20px;
  border-radius: 10px;
  transition: $style-change-duration;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (min-width: 1025px) {
    &:hover {
      box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
  
      svg {
        opacity: 1;
      }
    }
  }
  
  svg {
    flex-shrink: 0;
    margin-right: 20px;
    opacity: 0.5;
    transition: $style-change-duration;
  }

  div {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);

    h3 {
      @include font(18, 23);
      margin: 0;
      margin-bottom: 10px;
      font-weight: 400;
    }

    a {
      @include not-hover;
      @include font(14, 18);
      align-self: flex-start;
      color: #6388a8;
      text-decoration: none;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .advantages__item {
    margin-bottom: 45px;
  }
}

@media (max-width: $tablet-width) {
  .advantages {
    padding-top: 60px;
  }

  .advantages__list {
    flex-direction: column;
  }

  .advantages__item {
    align-items: flex-start;
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      width: 40px;
      height: 40px;
    }
  
    div {
      width: calc(100% - 60px);
    }
  }
}

.advantages--alt {
  margin-bottom: 100px;

  .advantages__item {
    flex-direction: column;
    align-items: flex-start;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 0;
    padding: 35px 30px;
    border-radius: 20px;
    background-color: #ffffff;
  
    &:nth-child(2n) {
      margin-right: 2%;
    }

    &:nth-child(3n) {
        margin-right: 0;
      }
  
    svg {
      margin-right: 0;
      margin-bottom: 20px;
      opacity: 1;
    }
  
    div {
      flex-grow: 1;
      width: 100%;
  
      h3 {
        margin-bottom: 20px;
      }

      a {
        margin-top: auto;
      }
    }
  }

  @media (max-width: $tablet-width) {
    margin-bottom: 60px;

    .advantages__item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      padding: 20px;
  
      &:nth-child(2n) {
        margin-right: 0;
      }
  
      svg {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
  
      div {
  
        h3 {
          margin-bottom: 20px;
        }

        a {
          margin-top: auto;
        }
      }
    }
  }
}

