.footer {
  margin-top: auto;
  padding-top: 48px;
  background-color: #97b7ca;
  color: #ffffff;
}

.footer__nav {
  column-count: 4;
  column-gap: 21px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer__logo {
  display: flex;
}

.footer__menu {
  @include list-reset;
  padding-top: 11px;
}

.footer__menu-item {
  display: flex;
  flex-direction: column;
  break-inside: avoid;
  margin-bottom: 32px;

  & > a {
    @include font(18, 23);
    @include not-hover;
    margin-bottom: 12px;
    text-decoration-color: transparent;
    text-decoration-skip-ink: none;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        text-decoration-color: #ffffff;
      }  
    }
  }

  ul {
    @include list-reset;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0
      }
    }

    a {
      @include font(14, 18);
      @include not-hover;
      text-decoration: none;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }
  }
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  padding-top: 11px;
}

.footer__contacts-title {
  @include font(18, 24);
  @include not-hover;
  margin-bottom: 12px;
  text-decoration-color: transparent;
  text-decoration-skip-ink: none;

  @media (min-width: 1025px) {
    &:hover {
      @include hover;
      text-decoration-color: #ffffff;
    }
  }
}

.footer__contacts-phones {
  margin-bottom: 10px;

  a {
    @include font(14, 18);
    @include not-hover;
    text-decoration: none;

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        opacity: 0.7;
      }
    }
  }
}

.footer__contacts-address {
  @include font(14, 18);
  max-width: 177px;
  margin: 0;
  margin-bottom: 20px;
}

.footer__socials {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    a {
      @include not-hover;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }
  }
}

.footer__license {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  .footer__submenu-title {
    display: none;
  }
}

.footer__cert {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer__submenu-title {
  @include not-hover;
  @include font(18, 23);
  margin-bottom: 20px;
  text-decoration-skip-ink: none;
  text-decoration-color: transparent;

  svg {
    display: none;
  }

  &[href]:hover {
    @media (min-width: 1025px) {
      @include hover;
      text-decoration-color: #ffffff;
    }
  }
}

.footer__submenu-list {
  @include list-reset;
  column-count: 4;
  column-gap: 21px;

  li {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    break-inside: avoid;

    span {
      @include font(14, 18);
      margin-bottom: 10px;
      font-weight: 600;
    }

    a {
      @include not-hover;
      @include font(14, 18);
      padding: 5px 0;
      text-decoration: none;

      &:hover {
        @media (min-width: 1025px) {
          @include hover;
          opacity: 0.5;
        }
      }
    }

    ul {
      @include list-reset;
      display: flex;
      flex-direction: column;

      li {
        margin-bottom: 0;
      }
    }
  }
}

.footer__reg {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer__bottom {
  padding: 20px 0 27px;

  p {
    @include font(14, 18);
    margin: 0;
    opacity: 0.5;
  }
}


@media (max-width: $desktop-width) {
  .footer__nav {
    column-count: 3;
    padding-bottom: 30px;
  }

  .footer__menu {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  .footer__menu-item {
    margin-bottom: 0;

    ul {
      display: none;
    }
  }

  .footer__contacts {
    padding-top: 0;
  }

  .footer__license,
  .footer__reg {
    padding: 20px 0;

    .footer__submenu-list li {
      margin-bottom: 0;
    }
  }

  .footer__submenu-list {
    display: none;
    column-count: 2;

    li {
      margin-bottom: 20px;
    }

    &.is-active {
      display: block;
      margin-top: 20px;
    }
  }

  .footer__submenu-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0;

    svg {
      flex-shrink: 0;
      display: block;
      margin-left: 20px;
      transition: $style-change-duration;
    }

    &.is-active {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  .footer__license .footer__submenu-title {
    display: flex;
    @include font(18, 23);
    text-decoration: none;
  }
}

@media (max-width: $tablet-width) {
  .footer {
    padding-top: 30px;
  }
  
  .footer__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-count: unset;
    column-gap: 0;
  }

  .footer__logo {
    margin-right: 20px;

    img {
      width: 83px;
      height: auto;
    }
  }

  .footer__menu {
    display: none;
  }

  .footer__contacts {
    margin-left: 0;
  }

  .footer__contacts-title {
    display: none;
  }

  .footer__contacts-phones {
    display: flex;
    flex-direction: column;
  }

  .footer__contacts-address {
    margin-bottom: 0;
  }

  .footer__socials {
    display: none;
  }

  .footer__bottom {
    padding: 30px 0 15px;
  }
}
