.blog {
  margin-bottom: 80px;
  padding-right: 30px;
  padding-left: 30px;

  .page-title {
    margin-bottom: 70px;
  }

  .section-title {
    margin-bottom: 50px;
    padding: 0 20px;
  }
}

.blog__sorting {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .sorting {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.blog__slider-container {
  overflow: hidden;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.blog__slider-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .slider-controls {
    margin-bottom: 0;
  }
}

.blog__tags {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;

  li {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    a {
      @include not-hover;
      @include font(14, 18);
      padding: 5px 0;
      color: #6388a8;
      text-decoration: none;

			@media (min-width: 1025px) {
				&:hover {
					@include hover;
					opacity: 0.5;
				}
			}
    }
  }
}

.blog__list {
  @include list-reset;
  display: flex;
}

.blog__item {
  @include not-hover;
  display: flex;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #ffffff;

	@media (min-width: 1025px) {
		&:hover {
			@include hover;
			box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
		}
	}
  
  a {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 25px 35px;
    text-decoration: none;

    span {
      @include font(14, 18);
      color: #6388a8;
      margin-bottom: 30px;
    }

    h3 {
      @include font(20, 23);
      margin: 0;
      margin-bottom: 30px;
      font-weight: 400;
    }

    p {
      @include font(14, 18);
      margin: 0;
      opacity: 0.5;
    }
  }
}

@media (max-width: $desktop-width) {
  .blog {
    .section-title {
      padding: 0;
    }
  }

  .blog__slider-container {
    padding: 0;
  }
}

@media (max-width: $tablet-width) {
  .blog {
    margin-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;

    .page-title {
      margin-bottom: 30px;
    }
  
    .section-title {
      margin-bottom: 20px;
    }
  }

  .blog__slider-controls {
    display: none;
  }

  .blog__list {
    flex-direction: column;
  }

  .blog__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
    a {
      padding: 20px;
    }
  }
}

.blog--page {
  padding-top: 70px;
  padding-left: 50px;
  padding-right: 50px;

  .blog__list {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .blog__item {
    flex-shrink: unset;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .button {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: $desktop-width) {
  .blog--page {
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;

    .blog__item {
      width: 49%;
      margin-right: 2%;
  
      &:nth-child(3n) {
        margin-right: 2%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  
  }
}

@media (max-width: $tablet-width) {
  .blog--page {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    .blog__sorting {
			flex-direction: column;
			margin-bottom: 33px;
		
			.sorting {
				margin-right: 0;
				margin-bottom: 20px;
		
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

    .blog__list {
      margin-bottom: 30px;
    }

    .blog__item {
      width: 100%;
      margin-right: 0;
  
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}