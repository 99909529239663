.page-title {
  @include font(55, 64);
  margin: 0;
  margin-bottom: 30px;
  font-weight: 300;
}

@media (max-width: $desktop-width) {
  .page-title {
      @include font(45, 55);
  }
}

@media (max-width: $tablet-width) {
  .page-title {
    @include font(30, 39);
  }
}