.service {
  background-color: #97b7ca;
}

.service__container {
  max-width: 1235px;
}

.service__buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;

  button {
    margin-right: 30px;
  }

  a {
    @include not-hover;
    @include font(14, 18);
    color: #f4f4f4;
    text-decoration: none;

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        opacity: 0.7;
      }
    }
  }
}

.service__description {
  padding-bottom: 70px;
  margin-top: 15px;

  p {
    @include font(18, 23);
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .alert {
    background-color: #f2f2f2;
    padding: 10px;
    padding-top: 20px;
    border-radius: 20px;
    box-shadow: 3px 10px 10px 0 rgba(0,0,0,.15),-5px -4px 35px 0 #fff;
    margin: 20px 0;

    ul {
      background-color: #6388a8;
      border-radius: 20px;
      color: white;
      padding: 15px 15px 15px 45px;
      margin-left: 20px;
    }
    ul li{
      color: white;
      margin-bottom: 10px;
    }

    ul li::marker {
      color: red;
      font-size: 1.5em;
      margin-left: 10px;
    }

  }
}

@media (max-width: $tablet-width) {
  .service__buttons {
    flex-direction: column;
    margin-bottom: 60px;

    button {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .service__description {
    padding-bottom: 60px;
  }
}
