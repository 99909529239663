.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
}

@media (max-width: $desktop-width) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: $tablet-width) {
  .container {
    padding: 0 20px;
  }
}
