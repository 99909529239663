.news__container {
  display: flex;
  flex-direction: column;
  max-width: 1035px;
}

.news__date {
  align-self: flex-end;
  margin-bottom: 70px;
  @include font(14, 18);
}

.news__description {
  padding-bottom: 70px;

  p {
    @include font(18, 23);
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

.news__list-wrapper {
  margin-top: 70px;
  margin-bottom: 70px;

  span {
    @include font(18, 23);
    margin-bottom: 20px;
  }

  ul,
  ol {
    padding-inline-start: 25px
  }

  li {
    @include font(18, 23);
  }

}

.news__quote {
  padding: 30px;
  background-color: #97b7ca;
  border-radius: 30px 0;

  p {
    margin: 0;

    &:last-of-type {
      margin-bottom: 33px;
    }
  }

  span {
    @include font(14, 16);
  }
}

@media (max-width: $tablet-width) {
  .news__date {
    margin-bottom: 60px;
  }

  .news__description {
    padding-bottom: 60px;

    img {
      margin-top: 40px;
      margin-bottom: 60px;
      height: 400px;
      object-fit: cover;
    }
  }

  .news__list-wrapper {
    margin-top: 60px;
    margin-bottom: 60px;

    span {
      font-size: 20px;
    }
  }

  .news__quote {
    padding: 30px 20px;
  }
}
