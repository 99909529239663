.slider-btn {
  @include not-hover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: rgba(62, 62, 62, 0.5);

  &.swiper-button-disabled {
    opacity: 0.3;
    cursor: auto;
  }

  @media (min-width: 1025px) {
    &:hover:not(.swiper-button-disabled) {
      @include hover;
      background-color: #97b7ca;
      color: #ffffff;
    }
  }

  &--prev {
    svg {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1300px) {

}

@media (max-width: $desktop-width) {

}
