.official-partner {
  margin-bottom: 100px;
  color: #ffffff;

  .section-title {
    max-width: 470px;
    margin-bottom: 20px;
  }
}

.official-partner__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 70px;
  border-radius: 20px;
  background-color: #638aA8;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

  img {
    margin-right: 94px;
  }
}

.official-partner__text {
  width: calc(100% - 307px);
  max-width: 643px;

  p {
    @include font(14, 18);
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $desktop-width)  {
  .official-partner__content {
    padding: 50px;

    img {
      margin-right: 50px;
    }
  }

  .official-partner__text {
    width: calc(100% - 263px);
  }  
}

@media (max-width: 900px) {
  .official-partner {
  
    .section-title {
      max-width: 100%;
    }
  }

  .official-partner__content {
    flex-direction: column;

    img {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .official-partner__text {
    width: 100%;
    max-width: 100%;
  }  
}

@media (max-width: $tablet-width) {
  .official-partner {
    margin-bottom: 60px;
  }
  
  .official-partner__content {
    padding: 20px;
  }
}