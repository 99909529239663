.undertitle-text {
  margin-bottom: 60px;

  p {
    max-width: 100%;
    margin: 0;
    margin-bottom: 30px;
    @include font(22, 28);
    font-weight: 300;
  }

  button {
    margin-left: 70%;
  }
}


@media (max-width: $tablet-width) {
  .undertitle-text {
    margin-bottom: 60px;

    p {
      max-width: 100%;
      @include font(20, 23);
      font-weight: 400;
    }
    button {
      margin-left: 40%;
    }
  }
}

@media (max-width: $mobile-width) {
  .undertitle-text {
    margin-bottom: 20px;

    p {
      max-width: 100%;
      @include font(17, 20);
      font-weight: 400;
    }
    button {
      margin-left: 40%;
    }
  }
}
