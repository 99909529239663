.accreditations {
    margin-bottom: 40px;
  .section-title {
    margin-bottom: 70px;
  }
}

.accreditations__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    width: 48.9%;
    margin-right: 2.2%;
    padding: 35px;
    border-radius: 20px;
    background-color: #f4f4f4;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
    transition: $style-change-duration;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @media (min-width: 1025px) {
      &:hover {
        background-color: #ffffff;
        box-shadow: none;
      }
    }
    
    p {
      @include font(18, 23);
      margin: 0;
      margin-bottom: 10px;
    }

    a {
      align-self: flex-start;
      @include not-hover;
      margin-top: auto;
      @include font(14, 18);
      color: #6388a8;
      text-decoration: none;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .accreditations {
    margin-bottom: 0;
  
    .section-title {
      margin-bottom: 30px;
    }
  }
  
  .accreditations__list {
    li {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      padding: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}