.slider-controls {
  display: flex;
  align-self: flex-end;
  margin-right: 72px;
  margin-bottom: 20px;
  padding: 5px;
  background-color: #f4f4f4;
  border-radius: 20px;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

  .slider-btn {
    margin-right: 20px;

    &:last-child {
    margin-right: 0;
    }
  }
}

@media (max-width: $desktop-width)  {
  .slider-controls {
    margin-right: 0;
  } 
}

@media (max-width: $tablet-width) {
  .slider-controls {
    align-self: center;
    margin-bottom: 30px;
  }
}