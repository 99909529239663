$text-color: #3e3e3e;

$style-change-duration: 0.3s;
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$desktop-width: 1024px; /* 1024-1279 рх */
$tablet-width: 767px; /* 768-1023 рх */
$mobile-width: 480px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

//@media (min-width: $desktop-min-width + 1)
