.team {
  margin-bottom: 100px;

  .page-header {
    margin-bottom: 100px;
  }
}

.team__slider-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.team__slider {
  @include list-reset;
  display: flex;
}

.team__item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 31px;
  }

  span {
    margin-bottom: 10px;
    @include font(14, 18);
    color: #6388a8;
  }

  p {
    margin: 0;
    @include font(18, 23);
  }
}

@media (max-width: $tablet-width) {

  .team {
    margin-bottom: 60px;
  
    .page-header {
      margin-bottom: 30px;
    }
  }

  .team__slider-container {
    .slider-controls {
      display: none;
    }
  }

  .team__slider {
    flex-direction: column;
  }

  .team__item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      border-radius: 20px;
    }
  }
}