html {
  box-sizing: border-box;
  height: 100%;
  font-weight: 400;
  font-family: "Roboto", "Arial", sans-serif;
  color: #3E3E3E;

  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  font-style: normal;
  font-size: 16px;
  line-height: 1.2;

  background-color: #f4f4f4;
}

.body--locked {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
