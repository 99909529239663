.about {
  padding-top: 70px;
  margin-bottom: 100px;
}

.about__intro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;

  img {
    margin-right: 90px;
  }
}

.about__text {
  max-width: 481px;
  margin-right: 20px;
  width: calc(100% - 478px);
  p {
    margin: 0;
    @include font(30, 39);
    font-weight: 300;
  }
}

.about__facts {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
}

.about__fact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
  margin-right: 2%;
  padding: 35px 30px;
  background-color: #ffffff;
  border-radius: 20px;

  &:nth-child(3n) {
    margin-right: 0;
  }

  svg {
    margin-bottom: 10px;
  }

  span {
    @include font(55, 64);
    margin-bottom: 10px;
    font-weight: 300;
    color: #6388a8;
    text-align: center
  }

  p {
    @include font(30, 39);
    margin: 0;
    font-weight: 300;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .about__intro { 
    img {
      margin-right: 0;
    }
  }
}

@media (max-width: $desktop-width) {
  .about__intro {
    margin-bottom: 60px;
    
    img {
      width: 300px;
    }
  }
  
  .about__text {
    width: calc(100% - 320px);
  }

  .about__fact {
    padding: 20px 15px 25px;
  
    span {
      @include font(40, 48);
    }
  
    p {
      @include font(26, 34);
    }
  }
}

@media (max-width: $tablet-width) {
  .about {
    margin-bottom: 60px;
    padding-top: 30px;
  }
  
  .about__intro {
    align-items: center;
    margin-bottom: 60px;

    img {
      display: none;
    }
  }
  
  .about__text {
    max-width: 100%;
    margin-right: 0;
    width: 100%;

    p {
      @include font(20, 23);
      font-weight: 400;
    }
  }


  .about__fact {
    flex-direction: column;
  }

  .about__fact {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    padding: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  
    span {
      @include font(55, 64);
    }
  
    p {
      @include font(30, 39);
    }
  }
}