.equipment {
  margin-bottom: 100px;

  .section-title {
    margin-bottom: 70px;
  }
}

.equipment__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 50px 97px 38px 20px;
  border-radius: 20px;
  background-color: #f4f4f4;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

  img {
    margin-left: -95px;
    margin-right: 42px;
  }
}

.equipment__text {
  @include font(14, 18);
  max-width: 645px;
  width: calc(100% - 360px);

  p {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: $desktop-width) {
  .equipment__content {
    flex-direction: column;
    padding: 30px;

    img {
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .equipment__text {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: $tablet-width) {
  .equipment {
    margin-bottom: 60px;
  
    .section-title {
      margin-bottom: 30px;
    }
  }
  
  .equipment__content {
    padding: 30px 20px;
  }
}