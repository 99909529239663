.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  backdrop-filter: blur(15px);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
  transform $trans-modal,
  visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 1120px;
    padding: 70px 103px;

    background-color: #f4f4f4;
    border-radius: 20px;

    .page-title {
      margin-bottom: 50px;
    }
  }

  &__close-btn {
    @include not-hover;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    padding: 0;

    border: none;
    cursor: pointer;

    @media (min-width: 1025px) {
      &:hover {
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        opacity: 0.5;
      }
    }
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

@media (max-width: 767px) {
  .modal {
    &__wrapper {
      padding: 40px 20px;
    }

    &__content {
      padding: 40px;

      .page-title {
        margin-bottom: 10px;
      }
    }

    &__close-btn {
      top: 20px;
      right: 20px;
      width: 25px;
      height: 25px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
