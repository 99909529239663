.sorting {
    position: relative;
    max-width: 236px;
  
    &.active {
      .sorting__header {
        z-index: 11;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
  
        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
  
      .sorting__parameters {
        display: flex;
      }
    }
  }
  
  .sorting__header {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 18px;
    line-height: 23px;
    border-radius: 20px;
    border: none;
    background-color: #ffffff;
    outline: none;
    color: #3e3e3e;
    padding: 8px 100px 8px 30px;
    text-align: left;
    white-space: nowrap;
  
    &::after {
      content: "";
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 19px;
      background-image: url("../img/sorting-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s;
    }
  
    @media (min-width: 1025px) {
      &:hover {
        outline: none;
        cursor: pointer;
      }
    }
  }
  
  .sorting__parameters {
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    flex-direction: column;
    min-width: 100%;
    padding-bottom: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #ffffff;
    z-index: 10;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

    label {
      span {
        font-size: 14px;
        line-height: 18px;
        display: flex;
        width: 100%;
        padding: 8px 30px;
      }
  
      input {
        @media (min-width: 1025px) {
          &:hover {
            & + span {
              outline: none;
              cursor: pointer;
              transition: 0.3s;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: $tablet-width) {
    .sorting {
      width: 100%;
      max-width: 100%;
    }
  }
  