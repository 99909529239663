.contacts {
  padding-bottom: 100px;
  background-color: #97b7ca;

  .page-title {
    margin-bottom: 100px;
  }
}

.contacts__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
}

.contacts__item {
  display: flex;
  flex-direction: column;
  width: 32%;
  margin-right: 2%;
  padding: 35px 30px;
  border-radius: 20px;
  background-color: #ffffff;
  transition: $style-change-duration;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media (min-width: 1025px) {
    &:hover {
      background-color: #f4f4f4;
      box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 rgba(255, 255, 255, 0.3);
    }
  }
  
  svg {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    p {
      margin: 0;
      @include font(18, 23);
    }

    a {
      @include not-hover;
      @include font(18, 23);
      text-decoration: none;

      @media (min-width: 1025px) {
        &:hover {
          @include hover;
          opacity: 0.5;
        }
      }
    }
  }

  & > a {
    margin-top: auto;
    @include font(14, 18);
    color: #6388a8;
    text-decoration: none;
    @include not-hover;

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .contacts {
    .page-title {
      margin-bottom: 60px;
    }
  }

  .contacts__item {
    padding: 20px;
  }
}

@media (max-width: $tablet-width) {
  .contacts {
    padding-bottom: 60px;
  }
  
  .contacts__item {
    width: 100%;
    min-height: 216px;
    margin-right: 0;
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}