.history {
  margin-bottom: 100px;

  .section-title {
    margin-bottom: 70px
  }
}

.history__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-direction: column;
    width: 28.1%;
    margin-right: 7.85%;

    &:nth-child(3n) {
      margin-right: 0;
    }

    span {
      align-self: flex-start;
      margin-bottom: 20px;
      padding: 9px 30px;
      @include font(18, 23);
      color: #6388a8;
      background-color: #ffffff;
      border-radius: 20px;
    }

    p {
      @include font(14, 18);
      margin: 0;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .history__list {
  
    li {
      width: 30%;
      margin-right: 5%;
    }
  }
}

@media (max-width: $desktop-width) {
  .history__list {
    flex-direction: column;

    li {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .history {
    margin-bottom: 78px;
  
    .section-title {
      margin-bottom: 60px
    }
  }
  
}