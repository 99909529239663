.intro {
  max-width: 1330px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 70px;
  background-image: url("../img/doc3.png"), url("../img/doc2.png"), url("../img/doc1.png");
  background-repeat: no-repeat;
  background-position: right 0 bottom -300px, right 5px bottom -260px, right 45px bottom -242px;
  transition: $style-change-duration;

  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    background-image: url("../img/doc3@2x.png"), url("../img/doc2@2x.png"), url("../img/doc1@2x.png");
    background-size: 877px, 903px, 889px;
  }

  &.scrolled {
    background-position: right -111px bottom -320px, right -40px bottom -260px, right 45px bottom -242px;
  }
}

.intro-text {
  @include font(12, 16);
  margin: 0;
  margin-bottom: 80px;
  color: $text-color;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.36px;
}

.intro__title {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 40px;
  @include font(86, 101);
  color: #8cadc5;

  span {
    max-width: 380px;
    @include font(30, 39);
    font-weight: 300;
    color: $text-color;
  }
}

@media (max-width: 1300px) {
  .intro__title {
    @include font(70, 85);
  }
}

@media (max-width: 1200px) {
  .intro__title {
    @include font(60, 75);
  }
}

@media (max-width: $desktop-width) {
  .intro-text {
    margin-bottom: 40px;
  }

  .intro {
    background-size: 70%;
    background-origin: content-box;
  }
}

@media (max-width: $tablet-width) {
  .intro {
    padding-top: 28px;
    padding-bottom: 60px;
    background-image: none;

    button {
      align-self: center;
    }
  }

  .intro__container {
    display: flex;
    flex-direction: column;
  }

  .intro-text {
    margin-bottom: 30px;
  }

  .intro__title {
    @include font(40, 47);

    span {
      @include font(20, 24);
      font-weight: 400;
      margin-top: 8px;
    }
  }
}
