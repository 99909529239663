.services {
  padding: 60px 0;
  background-color: #97b7ca;
}

.services__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.services__item {
  @include not-hover;
  display: flex;
  flex-direction: column;
  width: 49.3%;
  margin-right: 1.4%;
  border-radius: 10px;
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  transition: $style-change-duration;

  
  &:first-child {
    background-image: url("../img/cert-bg-1.png"), url("../img/cert-bg-2.png"), url("../img/cert-bg-3.png"), url("../img/cert-bg-4.png");
    
    background-position: top left 80px, top 32px left 65px, bottom left, top left;
  
    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/cert-bg-1@2x.png"), url("../img/cert-bg-2@2x.png"), url("../img/cert-bg-3@2x.png"), url("../img/cert-bg-4@2x.png");
      background-size: 173px, 189px, 175px, 123px;
    }

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        background-color: #ffffff;
        background-position: top -10px left 88px, top 45px left 65px, bottom 0 left -45px, top -12px left -10px;
      }
    }
  }

  &:last-child {
    background-image: url("../img/reg-bg-1.png"), url("../img/reg-bg-2.png"), url("../img/reg-bg-3.png"), url("../img/reg-bg-4.png");
    
    background-position: top left 30px, top left, top 55px left 78px, bottom left;
  
    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/reg-bg-1@2x.png"), url("../img/reg-bg-2@2x.png"), url("../img/reg-bg-3@2x.png"), url("../reg/cert-bg-4@2x.png");
      background-size: 228px, 144px, 155px, 207px;
    }

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        background-color: #ffffff;
        background-position: top -15px left 30px, top -10px left -10px, top 65px left 90px, bottom left -20px;
      }
    }
  }

  &:nth-child(2n) {
  margin-right: 0;
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 52px 65px 51px;
    text-decoration: none;
  }

  p {
    @include font(30, 39);
    max-width: 210px;
    margin: 0;
    font-weight: 300;
  }
}

.services__item-text {
  display: none;
}

.services__description {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.services__description-text {
  @include font(18, 23);
  max-width: 735px;
  width: calc(100% - 198px);
  margin: 0;
  margin-right: 30px;
}

@media (max-width: $desktop-width) {
  .services__list {
    flex-direction: column;
  }

  .services__item {
    width: 100%;
    min-height: 217px;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .services__description {
    justify-content: center;
  }

  .services__description-text {
    display: none;
  }
}

@media (max-width: $tablet-width) {
  .services {
    padding: 40px 0;
  }

  .services__list {
    margin-bottom: 30px;
  }
  
  .services__item {
    min-height: 104px;
    p {
      @include font(20, 23);
      max-width: 148px;
      font-weight: 400;
    }

    a {
      flex-grow: 1;
      padding: 17px 11px;
    }
  }
}