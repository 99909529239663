.header {
  position: relative;
  //padding: 15px 0;
  background-color: #ffffff;
}

.header__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header__logo {
  margin-right: 50px;
  padding: 10px 0;
}

.header__burger {
  display: none;
}

.header__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
}

.header__menu {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 50px;
}

.header__menu-item {
  margin-right: 50px;

  &:last-child {
    margin-right: 0;
  }
}

.header__menu-link {
  @include not-hover;
  @include font(16, 19);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  font-weight: 300;
  color: $text-color;
  text-decoration: none;

  svg {
    display: none;
  }

  &:hover {
    @media (min-width: 1025px) {
      @include hover;
      opacity: 0.5;
    }
  }
}

.header__contacts {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header__phone {
  margin-right: 38px;

  a {
    @include not-hover;
    @include font(18, 24);
    font-weight: 600;
    text-decoration: none;

    &:hover {
      @media (min-width: 1025px) {
        @include hover;
        opacity: 0.7;
      }
    }
  }
}

.header__socials {
  a {
    @include not-hover;
    text-decoration: none;

    &:hover {
      @media (min-width: 1025px) {
        @include hover;
        opacity: 0.7;
      }
    }
  }
}

.header__submenu {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  display: none;
  width: 100%;
  padding: 70px 0;
  background-color: #ffffff;

  &.is-active {
    display: block;
  }
}

.header__submenu-container {
  display: flex;
  width: 100%;
}

.header__submenu-cert {
  display: flex;
  flex-direction: column;
  padding-right: 55px;
  border-right: 1px solid rgba(62, 62, 62, 0.2);

  .header__submenu-list {
    display: block;
    column-count: 2;
    column-gap: 108px;

    li {
      break-inside: avoid;
    }
  }
}

.header__submenu-title {
  @include not-hover;
  @include font(20, 23);
  margin-bottom: 30px;
  text-decoration: none;

  &[href]:hover {
    @media (min-width: 1025px) {
      @include hover;
      color: #6388a8;
    }
  }
}

.header__submenu-list {
  @include list-reset;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    flex-direction: column;
    max-width: 263px;
    margin-bottom: 10px;

    span {
      @include font(14, 18);
      margin-bottom: 10px;
      font-weight: 600;
    }

    a {
      @include not-hover;
      @include font(14, 18);
      padding: 5px 0;
      text-decoration: none;

      &:hover {
        @media (min-width: 1025px) {
          @include hover;
          color: #6388a8;
        }
      }
    }

    ul {
      @include list-reset;
      display: flex;
      flex-direction: column;

      li {
        margin-bottom: 0;
      }
    }
  }
}

.header__submenu-reg {
  display: flex;
  flex-direction: column;
  padding-left: 55px;

  img {
    margin-top: 13px;
    border-radius: 10px;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
  }
}

.header__submenu-about {
  display: flex;
  flex-direction: column;
  width: 100%;

  ul {
    @include list-reset;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      @include not-hover;
      @include font(14, 18);
      padding: 5px 0;
      text-decoration: none;

      &:hover {
        @media (min-width: 1025px) {
          @include hover;
          color: #6388a8;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .header__menu {
    margin-right: 40px;
  }

  .header__menu-item {
    margin-right: 40px;
  }
}

@media (max-width: $desktop-width) {
  .header__logo {
    width: 120px;
    margin-right: 30px;
  }

  .header__menu {
    margin-right: 30px;
  }

  .header__menu-item {
    margin-right: 30px;
  }

  .header__phone {
    display: none;
  }

  .header__submenu {
    padding: 35px 0 45px;
  }

  .header__submenu-cert {
    padding-right: 25px;

    .header__submenu-list {
      column-gap: 40px;
    }
  }

  .header__submenu-reg {
    padding-left: 25px;
  }

  .header__submenu-list {
    li {
      a {
        @include font(12, 16);
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .header {
    padding: 28px 0 11px;

    &.nav-opened {
      min-height: 100vh;
      overflow: auto;
      padding-bottom: 40px;

      .header__nav {
        display: flex;
      }
    }
  }

  .header__container {
    // flex-direction: column;
    // align-items: flex-start;
  }

  .header__logo {
    width: 140px;
    margin-right: auto;
    padding: 0;
  }

  .header__burger {
    @include button-reset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }

  .header__nav {
    display: none;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-top: 10px;
  }

  .header__menu {
    flex-direction: column;
    align-items: stretch;
    margin-right: 0;
  }

  .header__menu-item {
    margin-right: 0;
    border-bottom: 1px solid rgba(62, 62, 62, 0.2);
  }

  .header__menu-link {
    padding: 10px 0;

    svg {
      transition: $style-change-duration;
      display: block;
    }

    &.is-active {
      svg {
        transform: rotate(45deg);

      }
    }
  }

  .header__submenu {
    position: static;
    padding: 20px 0 0;
  }

  .header__submenu-container {
    flex-direction: column;
    padding: 0;
  }

  .header__submenu-title {
    @include font(14, 18);
    margin-bottom: 20px;
  }

  .header__submenu-cert {
    padding-right: 0;
    border-right: none;

    .header__submenu-list {
      display: none;
    }
  }

  .header__submenu-reg {
    padding-left: 0;

    img {
        display: none;
    }
  }

  .header__submenu-list {
    display: none;
  }

  .header__submenu-about {
    .header__submenu-title {
      display: none;
    }

    ul {
      flex-direction: column;

      li {
        margin-right: 0;
        margin-bottom: 20px;
      }

      a {
        padding: 0;
      }
    }
  }

  .header__contacts {
    order: -1;
    border-bottom: 1px solid rgba(62, 62, 62, 0.2);
  }

  .header__phone {
    display: block;
    a {
      display: block;
      padding: 10px 0;
    }
  }

  .header__socials {
    display: none;
  }
}
