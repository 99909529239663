@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Roboto";

  font-display: swap;
  src: url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Roboto";

  font-display: swap;
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff");
}
