.partner {
  padding-top: 60px;
  padding-bottom: 104px;

  .section-title {
    margin-bottom: 40px;
  }
}

.partner__slider-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.partner__slider {
  @include list-reset;
  display: flex;
}

.partner__item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  div {
    @include not-hover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 136px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f4f4f4;
    box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        background-color: #ffffff;
        box-shadow: none;
  
        & + p {
          opacity: 0.5;
        }
      }
    }
  }

  p {
    @include font(14, 18);
    margin: 0;
    padding: 0 16px;
    opacity: 0;
    transition: $style-change-duration;
  }
}

@media (max-width: $desktop-width) {
  .partner__item {
    p {
      opacity: 0.5;
    }
  }
}

@media (max-width: $tablet-width) {
  .partner {
    padding-top: 60px;
    padding-bottom: 60px;

    .section-title {
      margin-bottom: 50px;
    }
  }

  .partner__item {
    div {
      background-color: #ffffff;
      box-shadow: none;
    }
    p {
      padding: 0;
    }
  }
}