.certification__group {
  padding: 100px 0 80px;
  border-top: 1px solid rgba(62, 62, 62, 0.1);

  &:first-child {
    background-image: url("../img/cert-bg.png");
    background-repeat: no-repeat;
    background-position: center;

    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/cert-bg@2x.png");
      background-size: 630px;
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;

    button {
      @include button-reset;
      display: none;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f4f4f4;
      box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;

      svg {
        color: #ffffff;
        background-color: #97B7CA;
        border-radius: 50%;
        transform: rotate(90deg);
        transition: $style-change-duration;
      }
    }
  }
}

.certification__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  
  li {
    @include not-hover;
    display: flex;
    width: 32%;
    min-height: 180px;
    margin-right: 2%;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #ffffff;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media (min-width: 1025px) {
      &:hover {
        @include hover;
        background-color: #6388a8;
        box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
        color: #ffffff;
      }
    }
    
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 35px;
      text-decoration: none;

      p {
        @include font(18, 23);
        margin: 0;
      }
    }
  }
}

@media (max-width: $desktop-width) {
  .certification__group {
    padding: 50px 0;

    &:first-child {
      background-image: none;
    }
    
    .section-title {
      margin-bottom: 30px;
    }
  }

  .certification__list {
    li {
      a {
        padding: 20px;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .certification {
    padding-bottom: 30px;
  }
  
  .certification__group {
    margin-bottom: 31px;
    padding: 0;
    border: none;

    &:last-child {
      margin-bottom: 0;
    }
  
    .section-title {
      
      button {
        display: flex;
      }
    }

    &.active {
      .section-title {
        button {
          svg {
            transform: rotate(-90deg);
          }
          
        }
      }

      .certification__list {
        display: flex;
      }
    }
  }

  .certification__list {
    display: none;
    flex-direction: column;
    padding-bottom: 30px;

    li {
      width: 100%;
      margin-right: 0;
      background-color: #6388a8;
      box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, 0.15), -5px -4px 35px 0 #ffffff;
      color: #ffffff;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        min-height: 180px;
        padding: 10px 20px;
      }
    }
  }
}