.laboratory {
  margin-bottom: 80px;

  .page-title {
    max-width: 700px;
  }
}

.laboratory__objects {
  .section-title {
    margin-bottom: 70px;
  }
}

.laboratory__objects-list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 23.5%;
    min-height: 136px;
    margin-right: 2%;
    margin-bottom: 20px;
    padding: 32px 12px;
    border-radius: 10px;
    background-color: #ffffff;

    &:nth-child(4n) {
      margin-right: 0;
    }

    svg {
      flex-shrink: 0;
      margin-right: 20px;
    }

    h3 {
      width: calc(100% - 70px);
      margin: 0;
      @include font(14, 18);
      font-weight: 400;
    }
  }
}

@media (max-width: $desktop-width) {
  
  .laboratory__objects-list {
    li {
      width: 48%;
      margin-right: 4%;
  
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .laboratory {
    margin-bottom: 60px;
  }

  .laboratory__objects {
    .section-title {
      margin-bottom: 30px;
    }
  }

  .laboratory__objects-list {
    li {
      width: 100%;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}